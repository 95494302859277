import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  Select,
  Input,
  Button,
  Field,
  Control,
  Columns,
  Column,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Capsule Calculator",
    link: "/capsule-volume-and-area-calculator/",
  },
]
const seeAlsoArray = [
  "/cone-volume-and-area-calculator",
  "/conical-frustum-volume-and-area-calculator",
  "/cube-volume-and-area-calculator",
  "/cylinder-volume-and-area-calculator",
  "/ellipsoid-volume-and-area-calculator",
  "/percentage-calculator",
  "/rectangular-volume-and-area-calculator",
  "/sphere-volume-and-area-calculator",
  "/square-pyramid-volume-and-area-calculator",
]

function CapsuleCalculatorVolumeArea(props) {
  let [result, setresult] = useState("")
  let radius = useRef("")
  let height = useRef("")
  let unitSelector = useRef("")

  function calcVolume(e) {
    let radiusValue = radius.current.value
    let heightValue = height.current.value
    let unit = unitSelector.current.value

    if (radiusValue === "" || isNaN(radiusValue)) {
      setresult(<strong> Please Enter Radius Value </strong>)
    } else if (heightValue === "" || isNaN(heightValue)) {
      setresult(<strong> Please Enter Height Value</strong>)
    } else {
      radiusValue = Number(radiusValue)
      heightValue = Number(heightValue)

      let volume =
        Math.PI *
        Math.pow(radiusValue, 2) *
        ((4 / 3) * radiusValue + heightValue)

      setresult(
        <span>
          Volume is : {volume.toFixed(2) + unit}
          <sup>3</sup>{" "}
        </span>
      )
    }
  }
  function calcArea(e) {
    let radiusValue = radius.current.value
    let heightValue = height.current.value
    let unit = unitSelector.current.value

    if (radiusValue === "" || isNaN(radiusValue)) {
      setresult(<strong> Please Enter Radius Value </strong>)
    } else if (heightValue === "" || isNaN(heightValue)) {
      setresult(<strong> Please Enter Height Value</strong>)
    } else {
      radiusValue = Number(radiusValue)
      heightValue = Number(heightValue)

      let area = 2 * Math.PI * radiusValue * (2 * radiusValue + heightValue)
      let lenght = 2 * radiusValue + heightValue

      setresult(
        <span>
          Surface Area : {area.toFixed(2) + unit}
          <sup>2</sup>
          <br />
          Lenght of Capsule : {lenght.toFixed(2) + unit}
        </span>
      )
    }
  }
  return (
    <Layout location={props.location}>
      <SEO
        title="Capsule Calculator Volume and Area"
        description="Capsule Calculator Volume and Area, Simple Calculator Volume, Surface Area, Lenght of Capsule, how to calculate Capsule Volume, surface area, length of the capsule."
        keywords={[
          "Capsule Calculator, capsule volume, capsule, calculate capsule, capsule area, fast calculate capsule,Capsule slant height, Capsule slant height calculator, Lenght of Capsule calculator, capsule volume calculation.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Capsule Volume and Area Calculator</Title>

        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>Radius</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  ref={radius}
                />
              </Control>
            </Field>
            <Field>
              <Control>
                <StaticButton>Height</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  ref={height}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Select Unit</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Select>
                  <select
                    aria-label="unitSelector"
                    className="staticSelect"
                    ref={unitSelector}
                  >
                    <option value="m">m</option>
                    <option value="cm">cm</option>
                    <option value="mm">mm</option>
                    <option value="km">km</option>
                    <option value="dm">dm</option>
                  </select>
                </Select>
              </Control>
            </Field>

            <Button
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              onClick={calcVolume}
            >
              Calculate Volume
            </Button>
            <Button
              borderColor="#1da1f2"
              color="white"
              backgroundColor="#1da1f2"
              hoverColor="#1da1f2"
              hoverBorderColor="#1da1f2"
              onClick={calcArea}
            >
              Calculate area
            </Button>

            <p className="h5 mt-3">{result}</p>
          </Column>
          <Column>
            <FlexDiv maxWidth="200px" margin="auto">
              <SmallImg
                filename="capsule.png"
                alt="Capsule radius and height"
              />
            </FlexDiv>
          </Column>
        </Columns>
        <br />

        <h3>How To Calculate Capsule Volume?</h3>
        <p>
          Capsule Volume =
          <strong>
            &pi; * radius<sup>2</sup> * ( 4/3 * radius + height) .
          </strong>
          <br />
          <strong>
            ex : Rectangle have radius = 15, height = 20 .<br /> Volume = &pi; *
            15<sup>2</sup> * ( 4/3 * 15 + 20) = 28274.33 m<sup>3</sup>
          </strong>
          .<br />
          <strong> &pi; = 3.14159265359 </strong>
          <br />
          The capsule is a basic three-dimensional geometric shape consisting of
          a cylinder with hemispherical ends.
        </p>
        <br />
        <h3>How To Calculate Capsule area?</h3>
        <p>
          The surface area formula =
          <strong>2 * &pi; * radius * ( 2 * radius + height) .</strong>
          <br />
        </p>
        <br />
        <h3>How To Calculate Capsule lenght ?</h3>
        <p>
          Capsule lenght =<strong> 2 * radius + height .</strong>
        </p>

        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default CapsuleCalculatorVolumeArea
